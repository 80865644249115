/*------------------------------------------------
// File Name:PDM_KeFu.js
// File Description:PDM_KeFu API方法js接口
// Author:zxl
// Create Time:2023/09/15 17:07:45
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _kefu: "/PDM_KeFu"
}

export default api


//-------------------创建对象-------------------
export function CreatePDM_KeFuEntity(parameter) {
    return axios({
        url: api._kefu + "/CreatePDM_KeFuEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetPDM_KeFuList(parameter) {
    return axios({
        url: api._kefu + "/GetPDM_KeFuList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetPDM_KeFuListByPage(parameter) {
    return axios({
        url: api._kefu + "/GetPDM_KeFuListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetPDM_KeFuEntity(parameter) {
    return axios({
        url: api._kefu + "/GetPDM_KeFuEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdatePDM_KeFuEntity(parameter) {
    return axios({
        url: api._kefu+ "/UpdatePDM_KeFuEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeletePDM_KeFuEntity(parameter) {
    return axios({
        url: api._kefu + "/DeletePDM_KeFuEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------修改启用/禁用---------------
export function UpateIsOff(parameter) {
    return axios({
        url: api._kefu + "/UpateIsOff",
        method: 'post',
        data: parameter
    })
}
