<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm">
          <!-- {{ PdmKefuEntity.PdmKefuName }}({{
                            PdmKefuEntity.PdmKefuID
                        }}) -->
          编辑
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm q-mr-sm">
        <q-input
          label="客服名称"
          dense
          outlined
          class="col-3"
          v-model="PdmKefuEntity.KeFuName"
        />
        <q-input
          label="标题"
          dense
          outlined
          class="col-3"
          v-model="PdmKefuEntity.KeFuTitle"
        />
        <q-input
          label="科室"
          dense
          outlined
          class="col-4"
          v-model="PdmKefuEntity.KeFuDept"
        />
        <q-input
          label="URL"
          dense
          outlined
          class="col-12"
          v-model="PdmKefuEntity.KeFuUrl"
        />
        <div class="col-6">
          <q-card bordered>
            <div class="q-my-xs">
              <div class="row">
                <div class="col-9">
                  <div class="text-subtitle1 q-pl-sm">
                    头像
                  </div>
                </div>
                <div class="col-3 q-pr-sm">
                  <q-btn
                    style="float:right"
                    color="primary"
                    size="11px"
                    label="上传"
                    @click="onAddFiles"
                  />
                </div>
              </div>
            </div>
            <q-separator />
            <q-img
              :src="
                PdmKefuEntity.Api_Url +
                  '/UploadImage/KeFu/' +
                  PdmKefuEntity.HospID +
                  '/' +
                  PdmKefuEntity.KeFuImages
              "
              spinner-color="white"
              style="height: 200px; max-width: 370px"
            />
          </q-card>
        </div>
        <q-input
          label="介绍"
          dense
          outlined
          type="textarea"
          class="col-12"
          v-model="PdmKefuEntity.KeFuNotes"
        />
      </div>
      <!-- 上传图片控件 -->
      <q-uploader
        label="Auto Uploader"
        ref="AddFiles"
        v-show="false"
        auto-upload
        :url="
          PdmKefuEntity.Api_Url + '/api/PDM_KeFu/UploadImage'
        "
        accept=".jpg, image/*"
        @uploaded="onUploadedImage"
        multiple
        :headers="[{ name: 'HospID', value: PdmKefuEntity.HospID }]"
      />
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  props: {},
  computed: {
    ...mapState("PdmKefu", ["PdmKefuShowDlg", "PdmKefuEntity", "PdmKefuList"]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("PdmKefu", ["actUpdatePdmKefu", "actGetPdmKefuList"]),
    onOK() {
      this.actUpdatePdmKefu({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmKefuEntity,
      }).then((res) => {
        if (res.Code == 0) {
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
    onAddFiles() {
      this.$refs.AddFiles.pickFiles();
      this.$refs.AddFiles.reset(); //将上传器重置为默认值；清空队列，中止当前上传
    },
    onUploadedImage(info) {
      var ReturnSign = JSON.parse(info.xhr.responseText).ReturnSign;
      this.PdmKefuEntity.KeFuImages = ReturnSign.filesname;
    },
  },
};
</script>
